
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { CheckboxSetting } from '@/components/registration/models/checkbox';

const defaultSetting: CheckboxSetting = {
  label: ''
};

@Component({
  name: 'LmsCheckbox'
})
export default class FieldCheckBox extends Mixins(Field) {
  @Prop({ default: () => defaultSetting })
  setting!: CheckboxSetting;
}
