
import { Prop, Component, Mixins, Watch } from 'vue-property-decorator';
import { saveAs } from 'file-saver';
import LmsCheckbox from '@/components/registration/FieldCheckBox.vue';
import FieldGroup from '@/components/registration/FieldGroup.vue';
import { FieldData } from '@/components/registration/models/field';
import { Checkbox } from '@/components/registration/models/checkbox';
import { renderTemplate } from '@/api/template';

@Component({
  components: { LmsCheckbox }
})
export default class FieldCheckBoxGroup extends Mixins(FieldGroup) {
  @Prop({ default: 'Switch all' })
  label!: string;
  @Prop({ default: () => ({}), required: true })
  checkboxes!: Checkbox[];

  value = this.checkboxes.reduce((total, current) => {
    total[current.name] = current.initial || false;
    return total;
  }, {} as FieldData);

  get allChecked() {
    return Object.keys(this.value).every(name => this.value[name]);
  }

  set allChecked(val) {
    Object.keys(this.value).forEach(name => {
      this.value[name] = val;
    });
  }

  @Watch('value', { deep: true })
  invalidate() {
    this.error = {};
  }

  async downloadFile(slug: string) {
    const { data: template } = await renderTemplate(slug);
    saveAs(new Blob([template], { type: 'text/html;charset=utf-8' }), `${slug}.html`);
  }
}
