import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise } from 'axios';
import { CONTENT_LOCALES } from '@/api/content';

export const renderTemplate = (slug: string): AxiosPromise<string> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: '/templating/templates/rendering',
    method: HTTP_METHODS.POST,
    data: { slug: slug, locale: CONTENT_LOCALES.kk }
  });
