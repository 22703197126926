
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FieldGroupRules, FieldData } from '@/components/registration/models/field';

@Component
export default class Field extends Vue {
  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ required: true })
  name!: string;
  @Prop({ required: false, default: () => ({}) })
  rules!: FieldGroupRules;

  value: FieldData = {};
  error = {};

  validate(): void {
    Object.keys(this.rules).forEach(rule => {
      const check = this.rules[rule](this.value);

      if (Object.keys(check).length && this.isValid) {
        this.error = check;
      }
    });
  }

  get isValid(): boolean {
    return !Object.keys(this.error).length;
  }
}
